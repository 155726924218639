body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.space-ar{
  display: flex;
  justify-content: space-around;
}
.MuiToolbar-root  {
  display: flex;
  justify-content: space-between;
text-align: center;

  color:#bbb
}
.line{
  width: 2px;
  height: 30px;
}
.sub{
  height: 40px;
  margin-left: 20%;
}
.flex{
  display: flex;
  
}
.flex1{
  display: flex;

  flex-direction: row-reverse;
}
.App{
  height: 100%;
  width: 100%;

  min-height: 100vh;
}
.h-100{
  height: 100%;
  width: 100%;
  min-height: 100vh;
}
.space-between{
  display: flex;
  
  justify-content: space-between;
}
.bg-400{
  margin-top: 100px;
 height: 50vh;
  border-radius: 25px;
  width: 30vw;
  border: 1px #ffffff black;
  background-color: white;
}
.w-40{
  width: 200px;
}
.w400{
  width: 75vw;
}
.text{
  width: 90%;
  height: 50px;
  padding: 0 20px;
  margin: 0 5% ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
/* input[type=text], select ,textarea{

} */
textarea,.select{
justify-content: center;
float: center;
  margin: 20px ;
  width: 100%;
}
.h-20 {
  height: 20vh;
}
label {
  
  padding: 3px 12px 0 0;
  display: inline-block;
}

.center
{
display: flex;
justify-content: center;
}
Button{

  height: 50px;
  padding: 0 20px;
  float: right;
 
  
}
.featured {
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.featuredItem {
  flex: 1;
  margin: 0px 20px;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}
@media only screen and (max-width: 600px) {
  .featured {
    margin-top: 2%;
    width: 90vw;
    display: block;
    justify-content: space-between;
  }
  .featuredItem {
    flex: 1;
    margin: 10px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
}


.featuredTitle{
    font-size: 20px;
    float: right;
}
.bg-color{
  padding: 6px;
  border-radius: 6px;
  background-color: rgba(179, 210, 1, 0.151);

}
.featuredMoneyContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
    
}

.featuredMoney{
 

    font-size: 30px;
    font-weight: 600;
}

.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.w100{
  width: 100%;
}
.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.featuredIcon.negative{
    color: red;
}

.featuredSub{
    font-size: 15px;
    color: gray;
    float: right; 
}
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
}
 /* CSS Libraries Used 

*Animate.css by Daniel Eden.
*FontAwesome 4.7.0
*Typicons

*/

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');

body, html {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #1d243d;
  padding: 0;
  margin: 0;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container{
  margin: 0;
  top: 50px;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  background-color: rgb( 33, 41, 66 );
  border-radius: 9px;
  border-top: 10px solid #8F9BBA;
  border-bottom: 10px solid  #8F9BBA;
  width: 400px;
  height: 500px;
  box-shadow: 1px 1px 108.8px 19.2px rgb(25,31,53);
}

.box h4 {
  font-family: 'Source Sans Pro', sans-serif;
  color: #5c6bc0; 
  font-size: 18px;
  margin-top:94px;;
}

.box h4 span {
  color: #dfdeee;
  font-weight: lighter;
}

.box h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 38px;
  color: #a1a4ad;
  letter-spacing: 1.5px;
  margin-top: -15px;
  margin-bottom: 70px;
}

.box input[type = "text"],.box input[type = "password"],.box input[type = "email"] {
  display: block;
  margin: 20px auto;
  background: #262e49;
  border: 0;
  border-radius: 5px;
  padding: 14px 10px;
  width: 320px;
  outline: none;
  color: #d6d6d6;
      -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
  
}
::-webkit-input-placeholder {
  color: #565f79;
}

.box input[type = "text"]:focus,.box input[type = "password"]:focus {
  border: 1px solid #79A6FE;
  
}
.box input[type = "email"]:focus,.box input[type = "password"]:focus {
  border: 1px solid #79A6FE;
  
}
a{
  color: #5c7fda;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

 label input[type = "checkbox"] {
  display: none; /* hide the default checkbox */
}

/* style the artificial checkbox */
label span {
  height: 13px;
  width: 13px;
  border: 2px solid #464d64;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  left: 7.5%;
}

.btn1 {
  border:0;
  background: #153187;
  color: #a1a4ad;
  border-radius: 100px;
  width: 340px;
  height: 49px;
  font-size: 16px;
  position: absolute;
  top: 79%;
  left: 8%;
  transition: 0.3s;
  cursor: pointer;
}

.btn1:hover {
  background: #a1a4ad;
}

.rmb {
  position: absolute;
  margin-left: -24%;
  margin-top: 0px;
  color: #dfdeee;
  font-size: 13px;
}

.forgetpass {
  position: relative;
  float: right;
  right: 28px;
}

.dnthave{
    position: absolute;
    top: 92%;
    left: 24%;
}

.typcn {
  position: absolute;
  left: 339px;
  top: 282px;
  color: #3b476b;
  font-size: 22px;
  cursor: pointer;
}      

.typcn.active {
  color: #7f60eb;
}

.error {
  background: #ff3333;
  text-align: center;
  width: 337px;
  height: 20px;
  padding: 2px;
  border: 0;
  border-radius: 5px;
  margin: 10px auto 10px;
  position: absolute;
  top: 31%;
  left: 7.2%;
  color: white;
  display: none;
}

.footer {
    position: relative;
   display: flex;
   justify-content: center;
    top: 605px;
    width: 100%;
    color: #78797d;
   width: 100vw;

    font-size: 14px;
    text-align: center;
}
.mll-1{
  margin-left: 50px;
  margin-right: 20px;
}
.chart {
margin-top: 50px;
  flex: 4;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  background-color: rgb(255, 255, 255);

}

.title {
  margin-bottom: 20px;
}

.chartGrid {
  stroke: rgb(228, 225, 225);
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 100px;
    height: 100px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid blue; /* Black */
    border-radius: 100%;
    animation: spinner 1.5s linear infinite;
}.spinner-container{
 
  margin-top: 30vh;
  display: flex;
  margin-left: 30vw;
  margin-right:30vw;
  justify-content: center;
  position: fixed;
}
.red{
  color: red;
}
.dot {
  margin: 10px;
  height: 50px;
  width: 50px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.flexjs{
  display: flex;
justify-content: space-around;

}
.flexsx{
  display: flex;

  flex-wrap: wrap ;
  flex:1;
  flex-grow: 2;
}
@media screen and (min-width: 600px) {
  .mobile{
    width: 80vw;
    
    }
}
@media screen and (max-width: 600px) {
  .mobile{
    width: 100vw;
    
    }
}
#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
  color: black;
  text-align: center ;
}.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.product-info {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.product-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-description {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.product-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.product-images img {
  max-width: 100%;
  max-height: 200px;
  margin: 10px;
}

.product-pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.product-price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-discounted-price {
  font-size: 16px;
  text-decoration: line-through;
}

.product-discount {
  font-size: 16px;
  color: green;
  font-weight: bold;
}

.product-sizes {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.product-size {
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #ccc;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-size:hover {
  background-color: #555;
}

.product-colors {
  display: flex;
  justify-content: center;
}

.product-color {
  width: 30px;
  height: 30px;
  margin: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.product-color:hover {
  transform: scale(1.2);
}
#customers {
  color: blue;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}


#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color:  #1976D2;
  color: black;
}
.W100{
  width: 100vw;
 
}
.main-body {
  padding: 15px;
}
.card {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
body{
  margin-top:20px;
  color: #1a202c;
  text-align: left;
  background-color: #e2e8f0;    
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3, .my-3 {
  margin-bottom: 1rem!important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100%!important;
}
.shadow-none {
  box-shadow: none!important;
}input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}